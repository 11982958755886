/* eslint-disable simple-import-sort/imports */
import './main.css';

import { createApp } from 'vue';

import { createHead } from '@unhead/vue';
import { globalCookiesConfig } from "vue3-cookies";
import VueClipboard from 'vue3-clipboard';
import UUID from 'vue3-uuid';
import * as Sentry from '@sentry/vue';
import vClickOutside from 'click-outside-vue3';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import { initPosthog } from '@ui-lib';

import App from '@/App.vue';
import router from '@/router';
import setupInterceptors from '@/services/setupInterceptors';

import { pinia } from './stores';

const head = createHead();

setupInterceptors(router);

// Init Cookies
globalCookiesConfig({
    expireTimes: "180d",
    path: "/",
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    secure: import.meta.env.VITE_ENV !== 'local',
    sameSite: "Lax",
});

// Init Posthog
initPosthog(import.meta.env.VITE_POSTHOG_TOKEN)

const app = createApp(App)
    .use(head)
    .use(pinia)
    .use(UUID)
    .use(VueClipboard)
    .use(vClickOutside)
    .use(VCalendar, {})
    .use(router);

if (import.meta.env.VITE_ENV !== 'local') {
    Sentry.init({
        app,
        dsn: 'https://ecaf4752a43e62974833c0bff66be79c@o335302.ingest.us.sentry.io/5355334',
        environment: import.meta.env.VITE_ENV,
        integrations: [ Sentry.browserTracingIntegration({ router }), ],
    });
}

app.mount('#app');
